import React, {useEffect} from 'react';
import './App.css';


function App() {
  useEffect(()=>{
    window.location.href = "https://calendly.com/mahmoudian-arash/30min";
  },[]);
  return (
    <div>

    </div>
  );
}

export default App;
